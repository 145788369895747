.pulseButton {
    color: #66bb6a;
    animation: pulse 1.2s ease-in-out;
    animation-iteration-count: infinite;
}

@keyframes pulse {
    0% {
        background-color: #66bb6a;
    }
    100% {
        background-color: #1b5e20;
    }
}
